import Konva from 'konva';
import { PlayersPositions, TacticId } from '../main';
import { PitchSize } from '../types';
import { Time } from '../utils/decorators';

import { OverlayTactic, OverlayTacticWithGlyphs } from '../utils/loaders';
import { overlayElementsFactory } from './overlay-elements/OverlayElementsFactory';
import { getPlayerReferences } from './overlay-elements/utils';
import { Teams } from './types';
import { filterOverlayTactics, findExcludedReferences } from './utils';

type SearchOverlayElement = {
  key: string;
  overlayTacticWithGlyphs: OverlayTacticWithGlyphs;
};

type RenderFrameOptions = {
  container: HTMLDivElement;
  frame: number;
  playersPositions: PlayersPositions;
  overlayTactics: OverlayTactic[];
  pitchSize: PitchSize;
  scale: number;
  teams: Teams;
  filters: {
    tactics?: TacticId[];
  };
};

export type OverlayElementDebugInfo = {
  id: string;
  priority: number;
  players: string[];
  isVisible: boolean;
  excludedPlayers: string[];
};

const generateOverlayTacticKey = (overlayTactic: OverlayTactic) =>
  `${overlayTactic.tacticTypeId}-${overlayTactic.startFrame}-${overlayTactic.endFrame}`;

export interface OverlayRendererConfig {
  useContainer: boolean;
  imageInterface: unknown;
}

export class OverlayRenderer {
  frame: number = 0;
  activeOverlayElements: SearchOverlayElement[] = [];
  availableOverlayTactics: OverlayTactic[] = [];
  layer: Konva.Layer;
  stage: Konva.Stage | undefined;
  useContainer: boolean;
  imageInterface: unknown;

  constructor(config: OverlayRendererConfig) {
    this.layer = new Konva.Layer({
      listening: false,
      x: 0,
      y: 0,
    });
    this.stage = undefined;
    this.useContainer = config.useContainer;
    this.imageInterface = config.imageInterface;
  }

  renderFrame({
    container,
    frame,
    playersPositions,
    overlayTactics,
    scale,
    teams,
    pitchSize,
    filters,
  }: RenderFrameOptions) {
    this.frame = frame;
    if (!this.stage) {
      if (this.useContainer) {
        this.stage = new Konva.Stage({
          container,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.stage = new Konva.Stage();
      }
      this.stage.add(this.layer);
    }

    if (this.useContainer && this.stage?.container() !== container) this.stage.container(container);

    if (this.stage.width() !== pitchSize.length * scale || this.stage.height() !== pitchSize.width * scale) {
      this.stage.width(pitchSize.length * scale);
      this.stage.height(pitchSize.width * scale);
    }

    this.setupCurrentFrame(frame, overlayTactics, filters);
    const overlayElements = this.resolveOverlayElementsPriorities(this.activeOverlayElements, frame);

    overlayElements.map((overlayElementGlyphs) => {
      overlayElementGlyphs?.update({
        frame,
        playersPositions,
        scale,
        pitchSize,
        teams,
      });
    });

    return this.getFrameInfo();
  }

  private getFrameInfo() {
    return {
      frameNumber: this.frame,
      frameTactics: this.getAvailableTacticIdsForCurrentFrame(),
      overlayElementsDebugInfo: this.getOverlayElementsForCurrentFrame(),
    };
  }

  private getAvailableTacticIdsForCurrentFrame(): TacticId[] {
    return this.availableOverlayTactics.map((overlayTactic) => overlayTactic.tacticTypeId);
  }

  private getOverlayElementsForCurrentFrame(): OverlayElementDebugInfo[] {
    return this.activeOverlayElements
      .map((overlayElement) => overlayElement.overlayTacticWithGlyphs.overlayElementsGlyphs)
      .flat()
      .filter((overlayElement) => this.frame >= overlayElement.startFrame && this.frame <= overlayElement.endFrame)
      .map((overlayElement) => ({
        id: overlayElement.overlayElementTypeId,
        priority: overlayElement.priority,
        players: getPlayerReferences(overlayElement.references),
        isVisible: overlayElement.isVisible,
        excludedPlayers: overlayElement.excludedPlayerIdsFromRender,
      }));
  }

  @Time()
  private resolveOverlayElementsPriorities(searchOverlayElements: SearchOverlayElement[], frame: number) {
    const overlayElementsWithKey = searchOverlayElements
      .map((activeOverlayTactic) => activeOverlayTactic.overlayTacticWithGlyphs.overlayElementsGlyphs)
      .flat();

    overlayElementsWithKey.forEach((overlayElementWithGlyphs) => {
      const playerReferences = getPlayerReferences(overlayElementWithGlyphs.references);
      const conflictingOverlayElement = overlayElementsWithKey.filter((o) => {
        return (
          frame >= o.startFrame &&
          frame <= o.endFrame &&
          o.overlayElementTypeId !== overlayElementWithGlyphs.overlayElementTypeId &&
          o.priority < overlayElementWithGlyphs.priority &&
          o.priority !== -1 &&
          playerReferences.some((r) => {
            return getPlayerReferences(o.references).includes(r);
          })
        );
      });

      const conflictingPlayerIds = getPlayerReferences(findExcludedReferences(conflictingOverlayElement));

      overlayElementWithGlyphs.setExcludedPlayerIdsFromRender(conflictingPlayerIds);
    });

    return overlayElementsWithKey;
  }

  private setupCurrentFrame(frame: number, overlayTactics: OverlayTactic[], filters: { tactics?: TacticId[] }) {
    const { availableOverlayTactics, filteredOverlayTactics } = filterOverlayTactics(
      frame,
      overlayTactics,
      filters.tactics,
    );

    this.availableOverlayTactics = availableOverlayTactics;

    this.cleanInvalidOverlayTactics(filteredOverlayTactics);
    this.setupOverlayTactics(filteredOverlayTactics);
  }

  private cleanInvalidOverlayTactics(overlayTactics: OverlayTactic[]) {
    const generatedKeys = overlayTactics.map((overlayElement) => generateOverlayTacticKey(overlayElement));

    this.activeOverlayElements = this.activeOverlayElements.filter((overlayElement) => {
      const isValidOverlayElement = generatedKeys.includes(overlayElement.key);

      if (!isValidOverlayElement) {
        overlayElement.overlayTacticWithGlyphs.overlayElementsGlyphs.forEach((e) => e?.removeFromLayer());
      }

      return isValidOverlayElement;
    });
  }

  private setupOverlayTactics(activeOverlayTactics: OverlayTactic[]) {
    const loadedOverlayTacticsKeys = this.activeOverlayElements.map((overlayTactic) => overlayTactic.key);

    const notInitializedOverlayTactics = activeOverlayTactics.filter(
      (overlayTactic) => !loadedOverlayTacticsKeys.includes(generateOverlayTacticKey(overlayTactic)),
    );

    this.initializeOverlayTactics(notInitializedOverlayTactics);
  }

  private initializeOverlayTactics(overlayTactics: OverlayTactic[]) {
    const overlayTacticsWithGlyphs: SearchOverlayElement[] = overlayTactics.map((overlayTactic) => {
      const overlayElementsGlyphs = overlayTactic.overlayElements.map((item) =>
        overlayElementsFactory(item, this.imageInterface),
      );

      overlayElementsGlyphs.forEach((overlayElementGlyph) => overlayElementGlyph?.addToLayer(this.layer));

      return {
        key: generateOverlayTacticKey(overlayTactic),
        overlayTacticWithGlyphs: {
          endFrame: overlayTactic.endFrame,
          overlayElementsGlyphs,
          startFrame: overlayTactic.startFrame,
          tacticId: overlayTactic.tacticTypeId,
        },
      };
    });

    this.activeOverlayElements = [...this.activeOverlayElements, ...overlayTacticsWithGlyphs];
  }
}
